import React, { useState } from "react";
import { TextField } from "@mui/material";
import axios from "axios";
import { useData } from "../context/context";
import { useAlert } from "../context/AlerterContext";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Call } from "./svg-components/Call";
import { WhatsApp } from "./svg-components/WhatsApp";
import { Link, useNavigate } from "react-router-dom";

const API_URL =
  "https://triverseadvertising.com/assure_website/api2/campaign_enquiries.php";

const LandinPageForm = ({ Slug }) => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [isCooldown, setIsCooldown] = useState(false); // Cooldown state
  const formattedSlug = Slug.replace(/-/g, "_");

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      campaign: Slug,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(3, "Name should have a minimum length of 3 characters"),
      phone: Yup.string()
        .required("Phone Number is required")
        .matches(
          /^[0-9]{10}$/,
          "Invalid Phone Number format (should be 10 digits)"
        ),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const formDataApi = new FormData();
      formDataApi.append("name", values.name);
      formDataApi.append("phone", values.phone);
      formDataApi.append("campaign", values.campaign);

      axios
        .post(API_URL, formDataApi, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          const alertType = response.status === 200 ? "success" : "error";
          const alertMessage =
            response.data.message || "Form submission successful!";
          //   showAlert("Form Submitted", alertMessage, alertType);

          if (response.status === 200) {
            resetForm();
            navigate(`/enquiry-submitted?campaign=${Slug}`);
          }
        })
        .catch((error) => {
          showAlert(
            "Error",
            "Network error occurred. Please try again.",
            "error"
          );
          console.error("Error:", error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const handleCustomSubmit = (e) => {
    e.preventDefault();

    // Prevent submission if cooldown is active
    if (isCooldown) {
      showAlert(
        "Error",
        "Please wait a few seconds before submitting again.",
        "error"
      );
      return;
    }

    formik.validateForm().then((formErrors) => {
      const errorMessages = Object.values(formErrors);

      if (errorMessages.length > 0) {
        if (errorMessages.length === 1) {
          showAlert("Error", errorMessages[0], "error");
          formik.handleSubmit();
        } else {
          showAlert("Error", "Please complete all required fields.", "error");
          formik.handleSubmit();
        }
      } else {
        formik.handleSubmit();
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: `${formattedSlug}_enquiry_submit`,
          formName: "Enquiry Form",
          campaignSlug: formattedSlug, // Assuming Slug is the campaign identifier
          formData: {
            name: formik.values.name,
            phone: formik.values.phone,
          },
        });

        // Set the cooldown state
        setIsCooldown(true);

        // Reset cooldown after 5 seconds
        setTimeout(() => {
          setIsCooldown(false);
        }, 3000); // Adjust this duration as necessary
      }
    });
  };
  return (
    <div className="enquireform mx-auto">
      <div className="mx-auto">
        <article className="col-12 mx-auto text-center pb-4 pt-xl-3 pt-lg-1">
          <span className="text-white">REQUEST A CALLBACK</span>
        </article>
      </div>
      <form onSubmit={handleCustomSubmit} className="collectionForm">
        <TextField
          type="text"
          id="name"
          variant="outlined"
          label="Name"
          name="name"
          value={formik.values.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
          //   helperText={formik.touched.name && formik.errors.name}
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <TextField
          type="tel"
          id="phone"
          variant="outlined"
          label="Contact Number"
          name="phone"
          value={formik.values.phone}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          //   helperText={formik.touched.phone && formik.errors.phone}
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <button
          type="submit"
          className="  button button--aylen button--round-l button--text-thick"
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? "Submitting..." : "SUBMIT"}
        </button>
      </form>
      <div className="col-12 float-start mt-4 flex-center justify-content-center">
        <div className="col-lg-5 col-xs-6 col-6 grid-center text-center text-white gap-1 whatsapp">
          <Link
            target="_blank"
            to="https://api.whatsapp.com/send/?phone=%2B919716664040&text=Hello%21+assurepathlabs.com%2C+I+am+looking+for+Support.&type=phone_number&app_absent=0"
            onClick={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: `${formattedSlug}_whatsapp_click`,
                formName: "Enquiry Form",
                campaignSlug: formattedSlug,
                clickType: "WhatsApp",
                phone: "+919716664040",
              });
            }}
          >
            <WhatsApp /> <p className="text-white">WhatsApp</p>
          </Link>
        </div>
        <div className="col-lg-5 col-xs-6 col-6 grid-center text-center text-white gap-1 call">
          <Link
            to="tel:0181-4667555"
            onClick={() => {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: `${formattedSlug}_call_click`,
                formName: "Enquiry Form",
                campaignSlug: formattedSlug,
                clickType: "Call",
                phone: "0181-4667555",
              });
              console.log(
                "Data Layer Pushed: ",
                `${formattedSlug}_call_click`,
                window.dataLayer
              );
            }}
          >
            <Call /> <p className="text-white">Call</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LandinPageForm;
